import React, { useState } from 'react';
import { IBaggageBulk } from '../../../__mocks__/bagMerger';
import BaggageListItem from './BaggageListItem';

interface IBaggageList {
  baggages: IBaggageBulk[];
}

const BaggageList = ({ baggages }: IBaggageList) => {
  const [selectedItems, setSelectedItems] = useState<{
    [key: number]: boolean;
  }>({});

  const [lastSelectedULD, setLastSelectedULD] = useState('');
  const handleSelectionChange = (uld: string) => {
    setLastSelectedULD(selectedItems[uld] ? '' : uld);
    setSelectedItems((prevState) => ({
      ...prevState,
      [uld]: !prevState[uld],
    }));
  };
  return (
    <div className="border-t-[1px] border-l-[1px] border-r-[1px] border-grey-12">
      {baggages.map((baggage) => (
        <BaggageListItem
          key={baggage.uld}
          baggage={baggage}
          selected={selectedItems[baggage.uld] || false}
          onSelectionChange={handleSelectionChange}
          isLastSelected={lastSelectedULD === baggage.uld}
        />
      ))}
    </div>
  );
};

export default BaggageList;
