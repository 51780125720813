import React from 'react';
import classNames from 'classnames';
import { IHold } from '../../../__mocks__/bagMerger';
import LoadPlanCompartment from './LoadPlanCompartment';
import { createUniqueId } from '../../../utils/createUniqueId';

interface ILoadPlanHold {
  hold: IHold;
}

const LoadPlanHold = ({ hold }: ILoadPlanHold) => {
  const { compartments } = hold;

  const containerClassName = `w-fit h-[100%] grid grid-flow-col auto-cols-auto`;

  return (
    <div className={containerClassName}>
      {compartments.map((compartment, index) => (
        <div key={compartment.name} className="flex">
          <LoadPlanCompartment
            key={createUniqueId()}
            compartment={compartment}
          />
          <div
            className={classNames(
              'border border-dashed border-r-[1px] border-primary',
              {
                hidden: index === compartments.length - 1,
              }
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default LoadPlanHold;
