import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import Title from '../Title/Title';
import { DEVICE_TYPE, PASSANGER_STATUS } from '../../utils/constants';
import { IHeroPassanger } from './types';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';

interface IHeroPassangerItem extends IHeroPassanger {
  index: number;
  className?: string;
  totalItems: number;
}

const getLeftBorder = (totalItems: number, index: number) =>
  (totalItems === 1 && index === 0) ||
  (totalItems === 2 && index === 0) ||
  (totalItems === 3 && index === 2) ||
  (totalItems === 4 && index === 2) ||
  (totalItems === 5 && index === 4)
    ? 'mobile:rounded-bl-[0px] rounded-bl-[8px]'
    : '';

const getRightBorder = (totalItems: number, index: number) =>
  (totalItems === 2 && index === 1) ||
  (totalItems === 3 && index === 3) ||
  (totalItems === 4 && index === 3)
    ? 'mobile:rounded-br-[0px] rounded-br-[8px]'
    : '';

const classTitle =
  'font-body-text text-primary text-12 mb-40 dark:text-grey-12';
const seatExpanded = 'fill-grey-25 mr-[10px]';
const seatNumberClass = 'text-grey-40 font-body-thin text-18';
const classExpansionData =
  'grid grid-rows-2 gap-y-[32px] mobile:gap-y-[0px] mobile:gap-x-[16px] grid-cols-[minmax(115px,_1fr)_1fr,_1fr]';
const subtitleClassNames =
  'font-head-light text-primary text-18 dark:text-grey-12';
const boardedNumber =
  'text-primary dark:text-white font-body-thin text-80 mobile:text-56 -mb-32 -mt-16 mobile:m-0 pt-[10px]';

const HeroPassangerItem = (props: IHeroPassangerItem) => {
  const {
    index,
    classId,
    seatsNo,
    boardedNo,
    checkedInNo,
    bookedNo,
    standbyNo,
    availableNo,
    className,
    totalItems,
    ...other
  } = props;

  const containerClassItem = classNames(
    'bg-white dark:bg-grey-90 p-[32px] mobile:pb-0',
    getLeftBorder(totalItems, index),
    getRightBorder(totalItems, index),
    className
  );

  let classType = '';
  classId === 1 && (classType = PASSANGER_STATUS.FIRST_CLASS);
  classId === 2 && (classType = PASSANGER_STATUS.BUSINESS_CLASS);
  classId === 3 && (classType = PASSANGER_STATUS.PREMIUM_ECONOMY_CLASS);
  classId === 4 && (classType = PASSANGER_STATUS.ECONOMY_CLASS);
  classId === 5 && (classType = PASSANGER_STATUS.INFANTS);

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  return (
    <div className={containerClassItem} {...other}>
      <div className="flex justify-between">
        <div className={classTitle}>{classType}</div>
        {Boolean(seatsNo) && (
          <div className="flex">
            <Icon
              variant="seat"
              height={20}
              width={20}
              className={seatExpanded}
            />
            <div className={seatNumberClass}>{seatsNo}</div>
          </div>
        )}
      </div>

      <div className={classExpansionData}>
        <Title title={PASSANGER_STATUS.BOARDED} className="row-span-2">
          <div className={boardedNumber}>{boardedNo}</div>
        </Title>
        {isMobile ? (
          <>
            <Title title={PASSANGER_STATUS.BOOKED}>
              <div className={subtitleClassNames}>{bookedNo}</div>
            </Title>
            {Boolean(availableNo) && (
              <Title title={PASSANGER_STATUS.AVAILABLE}>
                <div className={subtitleClassNames}>{availableNo}</div>
              </Title>
            )}
            <Title title={PASSANGER_STATUS.CHECKED_IN}>
              <div className={subtitleClassNames}>{checkedInNo}</div>
            </Title>
            <Title title={PASSANGER_STATUS.STANDBY}>
              <div className={subtitleClassNames}>{standbyNo}</div>
            </Title>
          </>
        ) : (
          <>
            <Title title={PASSANGER_STATUS.CHECKED_IN}>
              <div className={subtitleClassNames}>{checkedInNo}</div>
            </Title>
            <Title title={PASSANGER_STATUS.BOOKED}>
              <div className={subtitleClassNames}>{bookedNo}</div>
            </Title>
            <Title title={PASSANGER_STATUS.STANDBY}>
              <div className={subtitleClassNames}>{standbyNo}</div>
            </Title>
            {Boolean(availableNo) && (
              <Title title={PASSANGER_STATUS.AVAILABLE}>
                <div className={subtitleClassNames}>{availableNo}</div>
              </Title>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeroPassangerItem;
