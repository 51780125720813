import React from 'react';
import classNames from 'classnames';
import { IDeck, IHold } from '../../../__mocks__/bagMerger';
import LoadPlanHold from './LoadPlanHold';
import { createUniqueId } from '../../../utils/createUniqueId';

interface ILoadPlan {
  deck: IDeck;
}

const LoadPlan = ({ deck }: ILoadPlan) => {
  const { holds } = deck;
  const containerClassName = `flex h-full w-full pb-[10px] `;

  const renderTemperatureAndVentilation = (hold: IHold) => (
    <>
      <span className="self-center mx-4 text-primary">
        Temperature {hold.temperature}
      </span>
      <div className="top-0 flex items-center mx-[10px] border-l-[1px] border-r-[1px] border-grey-40 mb-[20px]">
        <div className="flex-grow border-dashed border-t-[1px] border-grey-40" />
        <span className="mx-4 text-primary">
          Ventilation {hold.ventilation}
        </span>
        <div className="flex-grow border-dashed border-t-[1px] border-grey-40" />
      </div>
    </>
  );

  return (
    <div className={containerClassName}>
      {holds.map((hold, index) => (
        <div key={hold.name} className="flex flex-col w-fit">
          {renderTemperatureAndVentilation(hold)}
          <div className="flex h-full w-full">
            <div
              className={classNames('border-l-[1px] border-primary ', {
                hidden: index !== 0,
              })}
            />
            <LoadPlanHold key={createUniqueId()} hold={hold} />
            <div className="border-r-[1px] border-primary " />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoadPlan;
