import React, { useEffect } from 'react';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import { DEVICE_TYPE, RELOAD } from '../../../utils/constants';
import {
  selectCountdown,
  decrementCountdown,
  refreshCountdown,
} from '../../../redux/reducers/reloadReducer';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from '../../../redux/reducers/notificationReducer';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';
import { useDeviceBreakpoint } from '../../../utils/hooks/useDeviceBreakpoint';

const textClassNames = 'font-body-text text-12 text-white leading-[12px]	';
const buttonClassNames =
  'h-24 flex justify-center items-center gap-x-1 bg-grey rounded-100 py-[6px] pl-10 pr-8 cursor-default	';

interface IReloadButton {
  refetchFunctions?: (() => void)[];
}

export const reloadRef = React.createRef<HTMLDivElement>();

const ReloadButton = ({ refetchFunctions }: IReloadButton) => {
  const dispatch = useDispatch();
  const countdown = useSelector(selectCountdown);
  const isOnline = useNetworkStatus();
  useEffect(() => {
    if (isOnline) {
      const intervalId = setInterval(() => {
        if (countdown > 0) {
          dispatch(decrementCountdown());
        } else {
          dispatch(clearNotifications());
          dispatch(refreshCountdown());
          refetchFunctions?.forEach((refetch: () => void) => {
            refetch();
          });
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [countdown, dispatch, isOnline, refetchFunctions]);

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  return (
    <div ref={reloadRef}>
      <Button
        role="button"
        Icon={<Icon variant="reload" width={14} height={14} />}
        text={`${isMobile ? '' : RELOAD} 0:${
          countdown < 10 ? '0' + countdown : countdown
        }`}
        textClassName={textClassNames}
        className={buttonClassNames}
      />
    </div>
  );
};

export default ReloadButton;
