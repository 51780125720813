import React, { memo, useEffect, useRef, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import './style.scss';
import Icon from '../Icon/Icon';
import classNames from 'classnames';

export interface Option {
  label: string;
  value: string;
}

interface DropdownProps {
  options: Option[];
  onSelectionChange: (options: Option[]) => void;
  className?: string;
}

const customValueRenderer = (
  selectedOpts: Option[],
  options: Option[],
  isMobile: boolean
) => {
  const selected =
    selectedOpts.length > 0
      ? selectedOpts.filter((station) => options.includes(station))
      : selectedOpts;
  return selected.length === options.length
    ? 'All'
    : selected.reduce((accumulator: string, current: Option, index: number) => {
        if (index === 0) {
          return (accumulator = current.label);
        } else if (index < 1 && isMobile) {
          return (accumulator = accumulator + ', ' + current.label);
        } else if (index < 2 && !isMobile) {
          return (accumulator = accumulator + ', ' + current.label);
        } else if (index === selected.length - 1) {
          return (
            accumulator +
            `, +${isMobile ? selected.length - 1 : selected.length - 2}`
          );
        }
        return accumulator;
      }, '');
};

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick: () => void;
}

export const ALL_OPTION = { label: 'All', value: '' };

const DefaultItemRenderer = ({
  checked,
  option: { label },
  onClick,
  disabled,
}: IDefaultItemRendererProps) => {
  return (
    <>
      {label !== 'Select All' ? (
        <div className={`item-renderer ${disabled ? 'disabled' : ''}`}>
          <input
            type="checkbox"
            onChange={onClick}
            checked={checked}
            tabIndex={-1}
            disabled={disabled}
          />
          <span>{label}</span>
        </div>
      ) : null}
    </>
  );
};

const Dropdown = memo(function Dropdown({
  options,
  onSelectionChange,
  className,
  ...props
}: DropdownProps) {
  const [selectedOptions, setSelectedOptions] = useState([ALL_OPTION]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    !isOpen && onSelectionChange(selectedOptions);
  }, [isOpen]);

  const useOutsideFunction = (ref: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
      const handleClickOutside = (event: Event) => {
        if (
          ref.current &&
          !ref.current.contains(event.target as HTMLDivElement)
        ) {
          setTimeout(() => {
            const element = document.querySelector(
              '[aria-labelledby="Select"]'
            );
            if (element?.ariaExpanded === 'false' && isOpen) {
              onSelectionChange(selectedOptions);
            }
            setIsOpen(element?.ariaExpanded === 'true');
          }, 50);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideFunction(wrapperRef);
  const handleOnClick = () => {
    setTimeout(() => {
      const element = document.querySelector('[aria-labelledby="Select"]');
      element?.ariaExpanded === 'true' ? setIsOpen(true) : setIsOpen(false);
    }, 50);
  };

  return (
    <div
      id="multi-select-dropdown"
      className={classNames(className, 'w-fit flex items-center')}
      onClick={handleOnClick}
      ref={wrapperRef}
      {...props}>
      <MultiSelect
        options={[ALL_OPTION, ...options]}
        value={selectedOptions}
        onChange={(optionArray: Option[]) => {
          const hasAllSelected = optionArray.findIndex(
            (o) => o.label === 'All'
          );
          const hasAllOptionsSelected =
            optionArray.filter((option) => option.label !== ALL_OPTION.label)
              .length === options.length;
          if (
            hasAllSelected > 0 ||
            optionArray.length === 0 ||
            hasAllOptionsSelected
          ) {
            setSelectedOptions([ALL_OPTION]);
          } else {
            const newOptionArray = optionArray.filter((o) => o.label !== 'All');
            setSelectedOptions(newOptionArray);
          }
        }}
        labelledBy="Select"
        ItemRenderer={DefaultItemRenderer}
        valueRenderer={() =>
          customValueRenderer(selectedOptions, options, isMobile)
        }
        ClearSelectedIcon={null}
        ArrowRenderer={() => (
          <>
            <Icon
              variant="filter"
              className="fill-primary dark:fill-white order-1 mr-8"
            />
          </>
        )}
        className="w-[125px] mobile:w-[80px]"
      />
    </div>
  );
});

export default Dropdown;
