import React, { useEffect, useState } from 'react';
import {
  ABOVE_WING,
  BELOW_WING,
  DEVICE_TYPE,
  GAUGE_LABEL,
} from '../../utils/constants';
import FlightStatus from './FlightStatus/FlightStatus';
import GaugeContainer from './GaugeContainer/GaugeContainer';
import { useNavigate } from 'react-router-dom';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { Permission, ProcessTime } from '../../utils/generated/graphql';
import classNames from 'classnames';
import ProcessList from './ProcessList';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';
import { selectRoles } from '../../redux/reducers/settingsReducer';
import { useSelector } from 'react-redux';
import Badge from '../Badge/Badge';
import Icon from '../Icon/Icon';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';

const flightDetailContainer =
  'flex flex-row-reverse tablet:grid tablet:grid-cols-3 mobile:flex-col justify-center gap-16 mobile:gap-24 mt-32 mobile:mt-16';

interface IFlightDetail {
  flightData: {
    flightStatus: string;
    acceptance: string;
    fueling: string;
    boarding: string;
    ramp: string;
    loadControl: string;
    departureTime: {
      time: string;
      delay: string;
    };
    countDown: string;
    targetOffBlockTime: { time: string; countDown: string };
    targetStartAprovalTime: { time: string; countDown: string };
    calculatedTakeOffTime: { time: string; countDown: string };
    mostPenalisingRegulationCause: {
      reason: string;
      locationCategory: string;
      iataDelayCode: string;
    };
  };
  passengerData: {
    value: number;
    max: number;
    tagArray: {
      isWarning: boolean;
      title: string;
    }[];
    tagsShown: number;
  };
  baggageData: {
    value: number;
    max: number;
    tagArray: {
      isWarning: boolean;
      title: string;
    }[];
    tagsShown: number;
  };
  flightId: string;
  redirectSource: string;
  isLoading: boolean;
  processList: [
    {
      id: number;
      name: string;
      targetStart: ProcessTime;
      targetEnd: ProcessTime;
      actualStart: ProcessTime;
      actualEnd: ProcessTime;
    }
  ];
}

const FlightDetail = ({
  flightData,
  passengerData,
  baggageData,
  flightId,
  redirectSource,
  isLoading,
  processList,
  ...others
}: IFlightDetail) => {
  const navigate = useNavigate();
  const roles = useSelector(selectRoles);
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const hasAboveWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_ABOVE_WING_DATA],
    CheckType.FULL
  );

  const hasBelowWingDataPermission = useCheckPermissions(
    [Permission.DISPLAY_PROCESS_PAGE_BELOW_WING_DATA],
    CheckType.FULL
  );

  const aboveWingClass = classNames('order-1 mobile:order-none tablet:w-auto', {
    'opacity-50 cursor-none pointer-events-none': !hasAboveWingDataPermission,
  });

  const belowWingClass = classNames('tablet:w-auto', {
    'opacity-50 cursor-none pointer-events-none': !hasBelowWingDataPermission,
  });

  const gaugeContainerClass = classNames(
    'flex justify-center items-center gap-[16px] dark:bg-grey-90 bg-white rounded-[8px] pt-[24px]',
    {
      'p-[24px]': !isMobile,
    }
  );

  const tagListClass =
    'm-auto flex flex-row flex-wrap max-w-[292px] justify-center gap-8 p-5 ';
  const tagTextClass = 'font-body-text dark:text-grey-12 text-primary';
  const tagClass = 'dark:bg-grey-100 bg-grey-6 max-w-[200px]';
  const additionalBadgeClass = 'dark:text-grey-12 text-primary';
  const iconClass = 'dark:fill-grey-12 fill-primary';

  const warningIcon = (
    <Icon width={11} height={9} variant="alert" className={iconClass} />
  );
  const infoIcon = (
    <Icon width={12} height={12} variant="info" className={iconClass} />
  );

  const renderMobileGaugeCounters = () => (
    <div className="dark:bg-grey-90 bg-white rounded-8 flex flex-col items-center tablet:p-[24px]">
      <div className={gaugeContainerClass}>
        <GaugeContainer
          title={ABOVE_WING}
          label={GAUGE_LABEL.PASSENGERS}
          status={flightData.flightStatus}
          value={passengerData.value}
          max={passengerData.max}
          className={aboveWingClass}
          handleOnClick={() =>
            flightId ? navigate(`/${redirectSource}/${flightId}/above`) : null
          }
          isLoading={isLoading}
        />
        <GaugeContainer
          title={BELOW_WING}
          label={GAUGE_LABEL.BAGGAGES}
          status={flightData.flightStatus}
          value={baggageData.value}
          max={baggageData.max}
          handleOnClick={() =>
            flightId ? navigate(`/${redirectSource}/${flightId}/below`) : null
          }
          isLoading={isLoading}
          className={belowWingClass}
        />
      </div>

      <div
        className={tagListClass}
        id="passenger-tags"
        onClick={() =>
          flightId ? navigate(`/${redirectSource}/${flightId}/above`) : null
        }>
        {passengerData.tagArray.map((item, index) =>
          index >= passengerData.tagsShown ? null : (
            <Badge
              key={item.title}
              Icon={item.isWarning ? warningIcon : infoIcon}
              text={item.title}
              textClassName={tagTextClass}
              className={tagClass}
            />
          )
        )}
        {passengerData.tagArray.length > passengerData.tagsShown && (
          <Badge
            text={`+${passengerData.tagArray.length - passengerData.tagsShown}`}
            textClassName={additionalBadgeClass}
            className={tagClass}
          />
        )}
      </div>
      <div
        className={tagListClass}
        id="baggages-tags"
        onClick={() =>
          flightId ? navigate(`/${redirectSource}/${flightId}/below`) : null
        }>
        {baggageData.tagArray.map((item, index) =>
          index >= baggageData.tagsShown ? null : (
            <Badge
              key={item.title}
              Icon={item.isWarning ? warningIcon : infoIcon}
              text={item.title}
              textClassName={tagTextClass}
              className={tagClass}
            />
          )
        )}
        {baggageData.tagArray.length > baggageData.tagsShown && (
          <Badge
            text={`+${baggageData.tagArray.length - baggageData.tagsShown}`}
            textClassName={additionalBadgeClass}
            className={tagClass}
          />
        )}
      </div>
    </div>
  );
  const renderDesktopAndTabletGaugeCounters = () => (
    <>
      <GaugeContainer
        title={ABOVE_WING}
        label={GAUGE_LABEL.PASSENGERS}
        status={flightData.flightStatus}
        value={passengerData.value}
        max={passengerData.max}
        tagArray={passengerData.tagArray}
        className={aboveWingClass}
        tagsShown={passengerData.tagsShown}
        handleOnClick={() =>
          flightId ? navigate(`/${redirectSource}/${flightId}/above`) : null
        }
        isLoading={isLoading}
      />
      <GaugeContainer
        title={BELOW_WING}
        label={GAUGE_LABEL.BAGGAGES}
        status={flightData.flightStatus}
        value={baggageData.value}
        max={baggageData.max}
        tagArray={baggageData.tagArray}
        tagsShown={baggageData.tagsShown}
        handleOnClick={() =>
          flightId ? navigate(`/${redirectSource}/${flightId}/below`) : null
        }
        isLoading={isLoading}
        className={belowWingClass}
      />
    </>
  );

  return (
    <div className={flightDetailContainer} {...others}>
      {FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_TURNAROUND_PROCESS_FEATURE_DISABLED ?? '',
        roles
      ) && <ProcessList processList={processList} />}
      <FlightStatus flightData={flightData} />
      {isMobile
        ? renderMobileGaugeCounters()
        : renderDesktopAndTabletGaugeCounters()}
    </div>
  );
};

export default FlightDetail;
