export const convertTime = (timeString: string, delay: boolean = false) => {
  if (timeString) {
    const hoursPart = timeString.includes('h')
      ? timeString.split('h')[0]
      : null;
    const minutesPart = timeString.includes('m')
      ? timeString.split('h').pop()?.split('m')[0].trim()
      : null;

    const hours = hoursPart ? parseInt(hoursPart) : 0;
    const minutes = minutesPart ? parseInt(minutesPart) : 0;

    if (hours > 1) {
      return hours + 'h+';
    }

    if (minutes > 60) {
      return `${Math.floor(minutes / 60)}h+`;
    }

    if (minutes > 0 && minutes <= 60) {
      if (delay) {
        return '+' + minutes + 'm';
      }
      return minutes + 'm';
    }
  }

  return '';
};
