import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConfig } from '../../redux/reducers/headerReducer';

interface IBestTimeLogic {
  timeLeft: string | null;
  timeLogicUsed: string | null;
  delay: number | null;
  time: string | null;
  time12format: string | null;
  timeString: string | null;
}

interface ITime {
  UTCDiff: string;
  UTCTime: string;
  bestTimeUTC: string;
  bestTimeUTC12format: string;
  localTime: string;
  bestTimeLocal: string;
  bestTimeLocal12format: string;
  airportCode: string;
}

interface IHeaderState {
  headerTypeMobile: 'simple' | 'flight';
  headerTypeTablet?: null | 'flight';
  themeColor?: null | string;
  hasBackArrow?: boolean;
  title?: string;
  flightName?: string;
  time?: string;
  departureAirport?: string;
  arrivalAirport?: string;
  gate?: string;
  position?: string;
  type?: string;
  registration?: string;
  bestTimeLogic?: IBestTimeLogic;
  departureTimes?: ITime;
  isMenuOpen?: boolean;
  isCheckedIn?: boolean;
  MCD?: string;
  showMCD?: boolean;
}

export const useCustomHeader = (config: IHeaderState) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(setConfig(config));
  }, [config]);
};
