import React from 'react';
import Title from '../../Title/Title';
import {
  DEVICE_TYPE,
  EMPTY_STATE,
  SPECIAL_LOADS,
} from '../../../utils/constants';
import Grid from '../../Grid/Grid';
import { useDeviceBreakpoint } from '../../../utils/hooks/useDeviceBreakpoint';

const containerClassNames = 'bg-white rounded-8 py-32 pl-32 dark:bg-grey-90';
const specialLoadsClassNames =
  'font-body-text text-primary text-12 mb-40 mobile:mb-32 dark:text-grey-12';
const subtitleClassNames =
  'font-head-light text-primary text-18 pt-4 leading-[19px] h-[19px] dark:text-grey-12';

interface ISpecialLoadsProps {
  DGR: number | string;
  AVIH: number | string;
  WCH: number | string;
  WEA: number | string;
  other: number | string;
}
const SpecialLoads = ({
  DGR,
  AVIH,
  WCH,
  WEA,
  other,
  ...others
}: ISpecialLoadsProps) => {
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  return (
    <div className={containerClassNames} {...others}>
      <div className={specialLoadsClassNames}>{SPECIAL_LOADS.TITLE}</div>
      <Grid col={[2, 0, 24]} mobile={[3, 0, 24]}>
        <Title title={SPECIAL_LOADS.DGR}>
          <div className={subtitleClassNames}>{DGR ?? EMPTY_STATE}</div>
        </Title>
        <Title title={SPECIAL_LOADS.AVIH}>
          <div className={subtitleClassNames}>{AVIH ?? EMPTY_STATE}</div>
        </Title>
        <Title title={isMobile ? SPECIAL_LOADS.WCH_MOBILE : SPECIAL_LOADS.WCH}>
          <div className={subtitleClassNames}>{WCH ?? EMPTY_STATE}</div>
        </Title>
        <Title title={SPECIAL_LOADS.WEAP}>
          <div className={subtitleClassNames}>{WEA ?? EMPTY_STATE}</div>
        </Title>
        <Title title={SPECIAL_LOADS.OTHER}>
          <div className={subtitleClassNames}>{other ?? EMPTY_STATE}</div>
        </Title>
      </Grid>
    </div>
  );
};

export default SpecialLoads;
