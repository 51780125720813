import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface ITitle {
  title: string;
  className?: string;
  titleColorClass?: string;
  children?: ReactNode;
  textClassName?: string;
}

const Title = (props: ITitle) => {
  const {
    title,
    children,
    titleColorClass = 'text-grey-40',
    className,
    textClassName,
    ...others
  } = props;

  const titleClassName = classNames(
    'font-body-text text-12 leading-[12px]',
    titleColorClass
  );

  return (
    <div className={className} {...others}>
      <p className={titleClassName}>{title}</p>
      <p className={textClassName}>{children}</p>
    </div>
  );
};

Title.displayName = 'Title';

export default Title;
