import React from 'react';
import Title from '../../Title/Title';
import {
  DEVICE_TYPE,
  EMPTY_STATE,
  INITIAL_STATE,
  PASSENGER_SPECIALS,
} from '../../../utils/constants';
import Grid from '../../Grid/Grid';
import { useDeviceBreakpoint } from '../../../utils/hooks/useDeviceBreakpoint';
import classNames from 'classnames';

const containerClassNames =
  'bg-white rounded-8 mobile:mx-24 mobile:p-24 py-32 pl-32 dark:bg-grey-90 tablet:col-span-1 laptop:col-span-1 desktop:col-span-1 mobile:order-4 order-3 max-h-[287px] mobile:min-h-[240px]';
const specialLoadsClassNames =
  'font-body-text text-primary text-12 mb-24 dark:text-grey-12';
const subtitleClassNames =
  'font-head-light text-primary text-18 dark:text-grey-12 leading-[19px] h-[19px mobile:pt-[4px]';

const wheelchairClassNames = 'text-12 font-body-text';

const titleColorClass = 'text-grey-40';
const subtitleClass =
  'text-12 font-body-text text-primary font-head-light leading-[18px] mt-4 dark:text-grey-12 h-[19px]';

interface IPassengerSpecialsProps {
  WCHR: string | number;
  WCHC: string | number;
  WCHS: string | number;
  StandBy: string | number;
  DEPA: string | number;
  DEPU: string | number;
  MEDA_MEQT: string | number;
  STRC: string | number;
  UMNR: string | number;
}
const PassengerSpecials = ({
  WCHR,
  WCHC,
  WCHS,
  StandBy,
  DEPA,
  DEPU,
  MEDA_MEQT,
  STRC,
  UMNR,
  ...others
}: IPassengerSpecialsProps) => {
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const wheelchairsTabletAndDesktop = [WCHR, WCHC, WCHS].some(
    (flag) => flag !== INITIAL_STATE
  ) && (
    <>
      <span>{WCHR ?? EMPTY_STATE}&nbsp;</span>
      <span className={wheelchairClassNames}>
        {PASSENGER_SPECIALS.WCHR}&nbsp;
      </span>
      <span>/&nbsp;{WCHC ?? EMPTY_STATE}&nbsp;</span>
      <span className={wheelchairClassNames}>
        {PASSENGER_SPECIALS.WCHC}&nbsp;
      </span>
      <span>/&nbsp;{WCHS ?? EMPTY_STATE}&nbsp;</span>
      <span className={wheelchairClassNames}>
        {PASSENGER_SPECIALS.WCHS}&nbsp;
      </span>
    </>
  );

  const renderMobile = () => {
    return (
      <>
        <div className={containerClassNames} {...others}>
          <div className={specialLoadsClassNames}>
            {PASSENGER_SPECIALS.TITLE}
          </div>
          <Grid col={[1, 0, 24]}>
            <div className="flex justify-between">
              <Title title={PASSENGER_SPECIALS.WCHR} className="basis-[70px]">
                <div className={subtitleClassNames}>{WCHR ?? EMPTY_STATE}</div>
              </Title>
              <Title title={PASSENGER_SPECIALS.WCHC} className="basis-[70px]">
                <div className={subtitleClassNames}>{WCHC ?? EMPTY_STATE}</div>
              </Title>
              <Title title={PASSENGER_SPECIALS.WCHS} className="basis-[70px]">
                <div className={subtitleClassNames}>{WCHS ?? EMPTY_STATE}</div>
              </Title>
            </div>

            <div className="flex justify-between">
              <Title
                title={PASSENGER_SPECIALS.STANDBY}
                className="basis-[70px]">
                <div className={subtitleClassNames}>
                  {StandBy ?? EMPTY_STATE}
                </div>
              </Title>
              <Title title={PASSENGER_SPECIALS.DEPA} className="basis-[70px]">
                <div className={subtitleClassNames}>{DEPA ?? EMPTY_STATE}</div>
              </Title>
              <Title title={PASSENGER_SPECIALS.DEPU} className="basis-[70px]">
                <div className={subtitleClassNames}>{DEPU ?? EMPTY_STATE}</div>
              </Title>
            </div>
            <div className="flex justify-between">
              <Title
                title={PASSENGER_SPECIALS.MEDA_MEQT}
                className="basis-[70px]">
                <div className={subtitleClassNames}>
                  {MEDA_MEQT ?? EMPTY_STATE}
                </div>
              </Title>
              <Title title={PASSENGER_SPECIALS.STRC} className="basis-[70px]">
                <div className={subtitleClassNames}>{STRC ?? EMPTY_STATE}</div>
              </Title>
              <Title title={PASSENGER_SPECIALS.UMNR} className="basis-[70px]">
                <div className={subtitleClassNames}>{UMNR ?? EMPTY_STATE}</div>
              </Title>
            </div>
          </Grid>
        </div>
      </>
    );
  };

  const renderTabletAndDesktop = () => {
    return (
      <>
        <div className={containerClassNames} {...others}>
          <div className={specialLoadsClassNames}>
            {PASSENGER_SPECIALS.TITLE}
          </div>
          <Title className="mb-[22px]" title={PASSENGER_SPECIALS.WHEELCHAIRS}>
            <div className={subtitleClassNames}>
              {wheelchairsTabletAndDesktop}
            </div>
          </Title>
          <Grid col={[2, 0, 24]}>
            <Title title={PASSENGER_SPECIALS.STANDBY}>
              <div className={subtitleClassNames}>{StandBy ?? EMPTY_STATE}</div>
            </Title>
            <Title title={PASSENGER_SPECIALS.DEPA}>
              <div className={subtitleClassNames}>{DEPA ?? EMPTY_STATE}</div>
            </Title>
            <Title title={PASSENGER_SPECIALS.DEPU}>
              <div className={subtitleClassNames}>{DEPU ?? EMPTY_STATE}</div>
            </Title>
            <Title title={PASSENGER_SPECIALS.MEDA_MEQT}>
              <div className={subtitleClassNames}>
                {MEDA_MEQT ?? EMPTY_STATE}
              </div>
            </Title>
            <Title title={PASSENGER_SPECIALS.STRC}>
              <div className={subtitleClassNames}>{STRC ?? EMPTY_STATE}</div>
            </Title>
            <Title title={PASSENGER_SPECIALS.UMNR}>
              <div className={subtitleClassNames}>{UMNR ?? EMPTY_STATE}</div>
            </Title>
          </Grid>
        </div>
      </>
    );
  };

  return <>{isMobile ? renderMobile() : renderTabletAndDesktop()}</>;
};

export default PassengerSpecials;
