import React from 'react';
import Title from '../Title/Title';
import Icon from '../Icon/Icon';
import classNames from 'classnames';
import Button from '../Button/Button';
import { useToggle } from '../../utils/hooks/useToggle';
import {
  DEVICE_TYPE,
  EMPTY_STATE,
  LIGHT_FONT_AIRLINES_BG,
  PASSANGER_STATUS,
} from '../../utils/constants';
import HeroPassangerItem from './HeroPassangerItem';
import { IHeroPassanger } from './types';
import { classes } from '../../pages/AboveTheWing/AboveTheWing';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';

const containerClass =
  'relative flex flex-col rounded-8 p-32 justify-between h-[287px] mobile:h-[unset] dark:bg-grey-90 gap-[24px]';
const numbersClass =
  'mt-4 leading-[19px] h-[19px] text-white text-18 font font-head-light';

interface IHeroPassengerProps {
  seats: number;
  boarded: number;
  checkedIn: number;
  booked: number;
  available: number;
  missing: number;
  jumps: number;
  mcd: string;
  showMCD: boolean;
  className: string;
  items: IHeroPassanger[];
  themeColor: string;
  type: string;
}

const HeroPassanger = ({
  seats,
  boarded,
  checkedIn,
  booked,
  available,
  missing,
  mcd,
  showMCD,
  jumps,
  className,
  items,
  themeColor,
  type,
  ...others
}: IHeroPassengerProps) => {
  const [isOpen, toggle] = useToggle(false);
  const arrowOnClickHandler = () => {
    toggle();
  };

  const classExpansion = classNames(
    'grid grid-cols-2 mobile:grid-cols-1 gap-[1px] tablet:col-span-2 laptop:col-span-2 desktop:col-span-2',
    { ' grid-rows-2': items?.length > 2 }
  );

  const titleColorClass = classNames({
    'text-white': LIGHT_FONT_AIRLINES_BG.includes(themeColor),
    'text-grey-40': !LIGHT_FONT_AIRLINES_BG.includes(themeColor),
  });

  const passengerStatusContainer =
    'grid grid-rows-2 gap-y-[32px] mobile:gap-y-[0px] mobile:gap-x-[16px] grid-cols-[minmax(115px,_1fr)_1fr,_1fr]';
  const subtitleClassNames =
    'font-head-light text-white text-18 dark:text-grey-12 mobile:text-16';
  const boardedNumber =
    'text-white font-body-thin text-80 -mb-32 -mt-16 mobile:m-0 pt-[10px] mobile:text-[56px]';

  const bookedInfants =
    items.find((item) => item.classId === classes.infants)?.bookedNo || 0;
  const totalBooked = booked
    ? `${booked}${bookedInfants ? ` + ${bookedInfants}` : ''}`
    : EMPTY_STATE;

  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);
  return (
    <div className={classNames(className, 'mobile:px-24 order-2')}>
      <div
        className={classNames({
          'bg-white dark:bg-grey-90 rounded-t-[9px]': isOpen,
        })}>
        <div className={classNames(containerClass, themeColor)} {...others}>
          <div className="flex justify-between">
            <Title
              title={PASSANGER_STATUS.PASSANGER_STATUS}
              className="flex items-center"
              titleColorClass={'text-white'}
            />
            <div className="flex flex-row items-center gap-10">
              <Icon variant="seat" height={16} width={16} />
              <p className="text-white font-head-light text-18">
                {seats ?? EMPTY_STATE}
              </p>
            </div>
          </div>

          {isMobile && (
            <div className={passengerStatusContainer}>
              <Title title={PASSANGER_STATUS.BOARDED} className="row-span-2">
                <div className={boardedNumber}>{boarded ?? EMPTY_STATE}</div>
              </Title>
              <Title title={PASSANGER_STATUS.BOOKED}>
                <div className={subtitleClassNames}>
                  {totalBooked ?? EMPTY_STATE}
                </div>
              </Title>
              <Title title={PASSANGER_STATUS.AVAILABLE}>
                <div className={subtitleClassNames}>
                  {available ?? EMPTY_STATE}
                </div>
              </Title>

              <Title title={PASSANGER_STATUS.CHECKED_IN}>
                <div className={subtitleClassNames}>
                  {checkedIn ?? EMPTY_STATE}
                </div>
              </Title>
              <Title title={PASSANGER_STATUS.JUMPS}>
                <div className={subtitleClassNames}>{jumps ?? EMPTY_STATE}</div>
              </Title>
            </div>
          )}

          {!isMobile && (
            <div className="flex tablet:flex-wrap justify-between">
              <div>
                <Title
                  titleColorClass={titleColorClass}
                  title={PASSANGER_STATUS.BOARDED}>
                  <p className="text-white font-body-thin text-80 h-[80px] leading-[80px]">
                    {boarded ?? EMPTY_STATE}
                  </p>
                </Title>
              </div>

              <div className="flex gap-40 self-end tablet:gap-[24px] mobile:mb-[unset] mb-10">
                <Title
                  titleColorClass={titleColorClass}
                  title={PASSANGER_STATUS.CHECKED_IN}
                  className="self-end">
                  <p className={numbersClass}>{checkedIn ?? EMPTY_STATE}</p>
                </Title>
                <Title
                  titleColorClass={titleColorClass}
                  title={PASSANGER_STATUS.BOOKED}
                  className="self-end">
                  <p className={numbersClass}>{totalBooked ?? EMPTY_STATE}</p>
                </Title>
                <Title
                  titleColorClass={titleColorClass}
                  title={PASSANGER_STATUS.AVAILABLE}
                  className=" self-end">
                  <p className={numbersClass}>{available ?? EMPTY_STATE}</p>
                </Title>
                <Title
                  titleColorClass={titleColorClass}
                  title={PASSANGER_STATUS.MISSING}
                  className=" self-end">
                  <p className={numbersClass}>{missing ?? EMPTY_STATE}</p>
                </Title>

                <Title
                  titleColorClass={titleColorClass}
                  title={PASSANGER_STATUS.JUMPS}
                  className=" self-end">
                  <p className={numbersClass}>{jumps ?? EMPTY_STATE}</p>
                </Title>

                <Button
                  role="button"
                  className="self-end ml-[20px]"
                  Icon={
                    <Icon
                      variant={!isOpen ? 'arrowExpand' : 'arrowCollapse'}
                      height={16}
                      width={18}
                      className="fill-white"
                    />
                  }
                  onClick={arrowOnClickHandler}
                />
              </div>
            </div>
          )}

          {isMobile && (
            <Button
              role="button"
              className="absolute bottom-12 self-center mobile:mr-[12px] "
              Icon={
                <Icon
                  variant={!isOpen ? 'arrowExpand' : 'arrowCollapse'}
                  height={16}
                  width={18}
                  className="fill-white"
                />
              }
              onClick={arrowOnClickHandler}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className={classExpansion}>
          {items &&
            items.map((item, index) => {
              return (
                <HeroPassangerItem
                  key={item.classId}
                  index={index}
                  totalItems={items.length}
                  {...item}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default HeroPassanger;
