import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';

interface IFavouriteButtonProps {
  onClick?: () => void;
  favourite?: boolean;
  disabled?: boolean;
}

const FavouriteButton = ({
  onClick,
  favourite = false,
  disabled,
}: IFavouriteButtonProps) => {
  const favouriteButtonClassNames = classNames(
    'px-15 py-13 border-1 rounded-4 mobile:w-[48px] mobile:h-[44px]',
    {
      'bg-white dark:bg-grey-90 border-grey-40 dark:border-bg-grey-12 ':
        !favourite,
      'bg-secondary border-secondary': favourite && !disabled,
      '!bg-grey-12': disabled,
    }
  );
  const iconClassNames = classNames({
    'fill-primary dark:fill-grey-12': !favourite && !disabled,
    'fill-white': favourite,
    'fill-grey': disabled,
  });

  const onClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <Button
      data-testid="favourite-button"
      className={favouriteButtonClassNames}
      Icon={<Icon variant="star" className={iconClassNames} />}
      onClick={onClickHandler}
      disabled={disabled}
    />
  );
};

export default FavouriteButton;
