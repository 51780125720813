import React from 'react';
import { IBaggageBulk } from '../../../__mocks__/bagMerger';
import classNames from 'classnames';
import Button from '../../Button/Button';

interface IBaggageListItem {
  baggage: IBaggageBulk;
  selected: boolean;
  onSelectionChange: (uld: string) => void;
  isLastSelected: boolean;
}

const separateTypeFromUld = (input: string) => {
  const validUldTypeLength = 3;
  if (input.length <= validUldTypeLength) {
    return input;
  }
  return (
    input.slice(0, validUldTypeLength) + ' ' + input.slice(validUldTypeLength)
  );
};

const BaggageListItem = ({
  baggage,
  selected = false,
  onSelectionChange,
  isLastSelected = false,
}: IBaggageListItem) => {
  const { uld, boardedPieces, totalPieces, destination } = baggage;
  const circleClassName = classNames(
    ' w-[25px] h-[25px] border-[1px] border-primary rounded-full',
    {
      'bg-secondary border-secondary': selected,
    }
  );

  const textClassName = 'text-primary font-bold text-grey-90';

  const containerClassName = classNames(
    'p-[20px] grid grid-cols-[70px,1fr,1fr,1fr,1fr]  place-items-center items-center  border-b-[1px] border-grey-12',
    {
      'bg-grey-12': isLastSelected,
    }
  );

  return (
    <div className={containerClassName}>
      <Button
        className={circleClassName}
        onClick={() => onSelectionChange(uld)}
      />
      <p className={classNames(textClassName, 'justify-self-start')}>
        {separateTypeFromUld(uld)}
      </p>
      <p className={textClassName}>Board {boardedPieces}</p>
      <p className={textClassName}>
        {baggage.uldType} {totalPieces}
      </p>
      <p className={textClassName}>{destination}</p>
    </div>
  );
};

export default BaggageListItem;
