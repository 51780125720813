import React from 'react';
import { ProcessTime } from '../../utils/generated/graphql';
import { useSelector } from 'react-redux';
import {
  select24Format,
  selectUTC,
} from '../../redux/reducers/settingsReducer';
import moment from 'moment';
import {
  EMPTY_FIELD,
  PROCESS_TIME_FORMATTING,
  LATE,
} from '../../utils/constants';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

export interface IProcessItem {
  name: string;
  targetStart: ProcessTime;
  targetEnd: ProcessTime;
  actualStart: ProcessTime;
  actualEnd: ProcessTime;
}

const ProcessItem: React.FC<IProcessItem> = ({
  name,
  targetStart,
  targetEnd,
  actualStart,
  actualEnd,
}: IProcessItem) => {
  const is24Format = useSelector(select24Format);
  const isUTC = useSelector(selectUTC);

  const currentTime = moment().utc();

  const getTimeHour = (time: ProcessTime) => {
    if (isUTC) {
      return is24Format
        ? time.utc !== ''
          ? time.utc
          : EMPTY_FIELD
        : time.utc12 !== ''
        ? time.utc12
        : EMPTY_FIELD;
    } else {
      return is24Format
        ? time.local !== ''
          ? time.local
          : EMPTY_FIELD
        : time.local12 !== ''
        ? time.local12
        : EMPTY_FIELD;
    }
  };

  const targetStartTime = getTimeHour(targetStart);

  const targetEndTime = getTimeHour(targetEnd);

  const actualStartTime = getTimeHour(actualStart);

  const actualEndTime = getTimeHour(actualEnd);

  const datesBeforeCompare = (firstDate: string, secondDate: string) => {
    return (
      firstDate !== EMPTY_FIELD &&
      moment(firstDate, PROCESS_TIME_FORMATTING).isSameOrBefore(
        moment(secondDate, PROCESS_TIME_FORMATTING)
      )
    );
  };

  const datesAfterCompare = (
    firstDate: string,
    secondDate: string,
    isLate: boolean
  ) => {
    if (isLate)
      return (
        firstDate === EMPTY_FIELD &&
        currentTime.isAfter(moment(secondDate, PROCESS_TIME_FORMATTING))
      );
    return (
      firstDate !== EMPTY_FIELD &&
      moment(firstDate, PROCESS_TIME_FORMATTING).isAfter(
        moment(secondDate, PROCESS_TIME_FORMATTING)
      )
    );
  };

  const isBeforeStartTime = datesBeforeCompare(
    actualStart?.utcTimestamp ?? '',
    targetStart?.utcTimestamp ?? ''
  );

  const isAfterStartTime = datesAfterCompare(
    actualStart?.utcTimestamp ?? '',
    targetStart?.utcTimestamp ?? '',
    false
  );

  const isBeforeEndTime = datesBeforeCompare(
    actualEnd?.utcTimestamp ?? '',
    targetEnd?.utcTimestamp ?? ''
  );

  const isAfterEndTime = datesAfterCompare(
    actualEnd?.utcTimestamp ?? '',
    targetEnd?.utcTimestamp ?? '',
    false
  );

  const isLateStart = datesAfterCompare(
    actualStartTime ?? '',
    targetStart?.utcTimestamp ?? '',
    true
  );

  const isLateEnd = datesAfterCompare(
    actualEndTime ?? '',
    targetEnd?.utcTimestamp ?? '',
    true
  );

  return (
    <div className="flex flex-row border-b-1 py-1 justify-between text-12">
      <div className="flex items-center w-[40%]">
        <div className="mr-1">
          {actualEndTime !== EMPTY_FIELD && (
            <Icon
              width={20}
              height={20}
              variant="roundCheckmark"
              className="fill-green"
            />
          )}
        </div>
        <div
          className={classNames(
            'justify-center text-primary content-center dark:text-grey-12',
            {
              'ml-[20px]': actualEndTime === EMPTY_FIELD,
            }
          )}>
          {name}
        </div>
      </div>
      <div className="text-grey-40 text-center min-w-[25%]">
        <div>{targetStartTime}</div>
        <div
          className={classNames({
            'text-red':
              isAfterStartTime ||
              (isLateStart && actualEndTime === EMPTY_FIELD),
            'text-primary dark:text-grey-40':
              isBeforeStartTime ||
              (targetStartTime === EMPTY_FIELD &&
                actualStartTime !== EMPTY_FIELD),
          })}>
          {isLateStart && actualEndTime === EMPTY_FIELD
            ? LATE
            : actualStartTime}
        </div>
      </div>

      <div className="text-grey-40 text-center pr-2 min-w-[25%]">
        <div>{targetEndTime}</div>
        <div
          className={classNames({
            'text-red': isAfterEndTime || isLateEnd,
            'text-primary dark:text-grey-40':
              isBeforeEndTime ||
              (targetEndTime === EMPTY_FIELD && actualEndTime !== EMPTY_FIELD),
          })}>
          {isLateEnd ? LATE : actualEndTime}
        </div>
      </div>
    </div>
  );
};

export default ProcessItem;
