import React from 'react';
import {
  CREW_STATUS,
  DEVICE_TYPE,
  LIGHT_FONT_AIRLINES_BG,
} from '../../utils/constants';
import Title from '../Title/Title';
import classNames from 'classnames';
import { useDeviceBreakpoint } from '../../utils/hooks/useDeviceBreakpoint';

const numbersClass =
  'text-white font-body-thin text-80 leading-[80px] h-[80px] min-w-[52px] mb-32 mobile:text-56';

interface IHeroCrew {
  cockpit?: number;
  cabin?: number;
  deadhead?: number;
  className?: string;
  themeColor: string;
}

const HeroCrew = ({
  cockpit,
  cabin,
  deadhead,
  className,
  themeColor,
  ...others
}: IHeroCrew) => {
  const isMobile = useDeviceBreakpoint(DEVICE_TYPE.MOBILE);

  const containerClass = classNames(
    'flex flex-col rounded-8 p-32 justify-start dark:bg-grey-90 mobile:p-24',
    themeColor,
    className
  );

  const titleColorClass = classNames({
    'text-white': LIGHT_FONT_AIRLINES_BG.includes(themeColor),
    'text-grey-40': !LIGHT_FONT_AIRLINES_BG.includes(themeColor),
  });
  return (
    <div className={containerClass} {...others}>
      <Title
        title={
          isMobile ? CREW_STATUS.CREW_STATUS_MOBILE : CREW_STATUS.CREW_STATUS
        }
        titleColorClass="text-white"
      />

      <div className="flex flex-row flex-nowrap pt-40 mobile:pt-24 gap-x-48 -mb-32 mobile:justify-between mobile:-mb-38">
        <Title titleColorClass={titleColorClass} title={CREW_STATUS.COCKPIT}>
          <p className={numbersClass}>{cockpit}</p>
        </Title>
        <Title titleColorClass={titleColorClass} title={CREW_STATUS.CABIN}>
          <div className={numbersClass}>{cabin}</div>
        </Title>
        <Title titleColorClass={titleColorClass} title={CREW_STATUS.DEADHEAD}>
          <div className={numbersClass}>{deadhead}</div>
        </Title>
      </div>
    </div>
  );
};

export default HeroCrew;
